import _export from "../internals/export";
import _functionCall from "../internals/function-call";
import _isObject from "../internals/is-object";
import _anObject from "../internals/an-object";
import _isDataDescriptor from "../internals/is-data-descriptor";
import _objectGetOwnPropertyDescriptor from "../internals/object-get-own-property-descriptor";
import _objectGetPrototypeOf from "../internals/object-get-prototype-of";
var $ = _export;
var call = _functionCall;
var isObject = _isObject;
var anObject = _anObject;
var isDataDescriptor = _isDataDescriptor;
var getOwnPropertyDescriptorModule = _objectGetOwnPropertyDescriptor;
var getPrototypeOf = _objectGetPrototypeOf; // `Reflect.get` method
// https://tc39.es/ecma262/#sec-reflect.get

function get(target, propertyKey
/* , receiver */
) {
  var receiver = arguments.length < 3 ? target : arguments[2];
  var descriptor, prototype;
  if (anObject(target) === receiver) return target[propertyKey];
  descriptor = getOwnPropertyDescriptorModule.f(target, propertyKey);
  if (descriptor) return isDataDescriptor(descriptor) ? descriptor.value : descriptor.get === undefined ? undefined : call(descriptor.get, receiver);
  if (isObject(prototype = getPrototypeOf(target))) return get(prototype, propertyKey, receiver);
}

$({
  target: "Reflect",
  stat: true
}, {
  get: get
});
export default {};